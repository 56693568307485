import { createWebHistory, createRouter } from 'vue-router';
import MainMobile from '../views/MainMobile.vue';
import history from '../views/about/history.vue';
import vision from '../views/about/vision.vue';
import CiInfo from '../views/about/CiInfo.vue';
import Contact from '../views/about/Contact.vue';

import Product from '../views/Product.vue';
import Project from '../views/Project.vue';
import ProjectDetail from "../views/ProjectDetail.vue";
import members from '../views/people/Members.vue';
import department from '../views/people/Department.vue';
import talent from '../views/people/Talent.vue';

import process from '../views/recruit/Process.vue';
import RecruitList from '../views/recruit/RecruitList.vue';
import RecruitListDetail from '../views/recruit/RecruitListDetail.vue';
import apply from '../views/recruit/Apply.vue';
import bimil from '../views/bimilMobile.vue'

const routes = [
    {
      path: '/',
      name: 'MainMobile',
      component: MainMobile,
    },
    {
      path: '/about/history',
      name: 'history',
      component: history,
    },
    {
      path: '/about/vision',
      name: 'vision',
      component: vision,
    },
    {
      path: '/about/ci',
      name: 'CiInfo',
      component: CiInfo,
    },
    {
      path: '/about/contact',
      name: 'Contact',
      component: Contact,
    },
    {
      path: '/product',
      name: 'Product',
      component: Product,
    },
    {
      path: '/project',
      name: 'Project',
      component: Project,
    },
    {
      path: '/project:name',
      name: 'ProjectDetail',
      component: ProjectDetail,
    },
    {
      path: '/people/members',
      name: 'members',
      component: members,
    },
    {
      path: '/people/department',
      name: 'department',
      component: department,
    },
    {
      path: '/people/talent',
      name: 'talent',
      component: talent,
    },
    {
      path: '/recruit/process',
      name: 'process',
      component: process,
    },
    {
      path: '/recruit/list',
      name: 'RecruitList',
      component: RecruitList,
    },
    {
      path: '/recruit/list:name',
      name: 'RecruitListDetail',
      component: RecruitListDetail,
    },
    {
      path: '/recruit/apply',
      name: 'apply',
      component: apply,
    },
    {
      path: '/bimil',
      name: 'bimil',
      component: bimil,
      beforeEnter: () => {
        window.location.href = 'https://bimil.bimpeers.com/';
      },
    },
];



const router = createRouter({
  history: createWebHistory(),
  routes,
  // scrollBehavior(to) {
  //   // 해시값이 있는 경우 해당 해시로 이동
  //   if (to.hash) {
  //     return { selector: to.hash, behavior: 'smooth' };
  //   }
  // },
});

export default router;
